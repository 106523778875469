import { tns } from 'tiny-slider/src/tiny-slider.module.js';

class Carousels {
	constructor() {
		this.init();
	}

	init() {
		this.carouselEls = Array.from(document.querySelectorAll('[data-ui-component="carousel"]'));
		this.carousels = [];

		if (this.carouselEls) {
			this.buildCarousels();
		}
	}

	buildCarousels() {
		this.carouselEls.forEach(carouselEl =>
			this.carousels.push(
				tns({
					container: carouselEl.querySelector('[data-ui-component="carousel-slides"]'),
					items: 1,
					slideBy: 1,
					autoplay: true,
					controls: false,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					nav: true,
					navContainer: carouselEl.querySelector('[data-ui-component="carousel-nav"]'),
					touch: true,
					mouseDrag: false,
				})
			)
		);
	}
}

export default new Carousels();
