import Siema from 'siema';

class TestimonialsCarousel {
	constructor() {
		this.init();
	}

	init() {
		this.SLIDE_DURATION = 10000;
		this.CarouselWrapperEl = document.querySelector('[data-ncomponent="single-carousel"]');

		if (this.CarouselWrapperEl) {
			this.CarouselEl = this.CarouselWrapperEl.querySelector(
				'[data-ncomponent="single-carousel-items"]'
			);
			this.NextBtn = this.CarouselWrapperEl.querySelector('[data-nbutton="single-carousel-next"]');
			this.PrevBtn = this.CarouselWrapperEl.querySelector('[data-nbutton="single-carousel-prev"]');

			this.activeSlideIndex = 0;

			if (this.CarouselEl) {
				this.buildCarousel();
			}
		}
	}

	buildCarousel() {
		this.SlideEls = Array.from(
			this.CarouselEl.querySelectorAll('[data-ncomponent="single-carousel-item"]')
		);

		this.Carousel = new Siema({
			selector: this.CarouselEl,
			duration: 500,
			easing: 'ease-out',
			perPage: 1,
			startIndex: this.activeSlideIndex,
			draggable: true,
			multipleDrag: false,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: () => {
				this.NextBtn.addEventListener('click', e => {
					e.preventDefault();
					this.Carousel.next(1, () => this.setActiveSlide());
				});
				this.PrevBtn.addEventListener('click', e => {
					e.preventDefault();
					this.Carousel.prev(1, () => this.setActiveSlide());
				});
				this.setActiveSlide();
			},
			onChange: () => {
				this.activeSlideIndex = this.Carousel.currentSlide;
				this.setActiveSlide();
			},
		});

		this.isPaused = false;

		this.frame = () => {
			if (!this.isPaused) {
				this.clearTimer();
				this.NextBtn.click();
			}
		};
		this.timer = setInterval(this.frame, this.SLIDE_DURATION);

		this.CarouselEl.addEventListener('mouseenter', () => {
			this.isPaused = true;
		});
		this.CarouselEl.addEventListener('mouseleave', () => {
			this.isPaused = false;
			this.clearTimer();
		});
	}

	setActiveSlide() {
		if (this.activeSlideIndex > this.SlideEls.length - 1) {
			this.activeSlideIndex = 0;
		}

		if (this.activeSlideIndex < 0) {
			this.activeSlideIndex = this.SlideEls.length - 1;
		}

		this.SlideEls.forEach((slide, i) => {
			slide.classList.remove('active');

			if (i === this.activeSlideIndex) {
				slide.classList.add('active');
			}
		});
		this.clearTimer();
	}

	clearTimer() {
		clearInterval(this.timer);
		this.timer = setInterval(this.frame, this.SLIDE_DURATION);
	}
}

export default new TestimonialsCarousel();
