class AutoResizeVideos {
	constructor() {
		this.init();
	}

	init() {
		this._allVideos = Array.from(document.querySelectorAll("iframe[src*='//www.youtube.com']"));

		this._allVideos.forEach(el => {
			el.dataset.aspectRatio = el.height / el.width;
			el.width = '100%';
			el.height = el.clientWidth * el.dataset.aspectRatio;
		});

		this.resizeVideoHeights();

		window.addEventListener('resize', () => this.resizeVideoHeights());
	}

	resizeVideoHeights() {
		this._allVideos.forEach(el => {
			el.height = el.clientWidth * el.dataset.aspectRatio;
		});
	}
}

export default new AutoResizeVideos();
