class Sharer {
	constructor() {
		this.init();
	}

	init() {
		this._shareLinks = Array.from(document.querySelectorAll('[data-ui-component="sharer"]'));

		if (this._shareLinks.length > 0) {
			this.updateShareLinks();
		}
	}

	updateShareLinks() {
		this._shareLinks.forEach(shareLink => {
			// Add the native share links if the browser supports them
			// and hide the href share links
			if (navigator.share) {
				shareLink.addEventListener('click', e => {
					e.preventDefault();
					navigator
						.share({
							title: shareLink.dataset.uiTitle,
							text: shareLink.dataset.uiDesc,
							url: shareLink.dataset.uiUrl,
						})
						.then(() => console.log('Successful share'))
						.catch(error => console.log('Error sharing', error));
				});
			} else {
				shareLink.classList.add('hide');
				const nextSibling = shareLink.nextElementSibling;
				if (nextSibling.classList.contains('share-links')) {
					nextSibling.classList.remove('hide');
				}
			}
		});
	}
}

export default new Sharer();
