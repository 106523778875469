import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';
import pswpMarkup from './photoswipe-markup';

class ImageGalleries {
	constructor() {
		this.init();
	}

	init() {
		this._galleries = Array.from(document.querySelectorAll('[data-ncomponent="gallery"]'));
		this._pswpEl = document.querySelector('.pswp');
		this._galleryToggles = Array.from(document.querySelectorAll('[data-ntoggle="gallery"]'));

		if (this._galleries.length > 0 && !this._pswpEl) {
			document.body.insertAdjacentHTML('beforeend', pswpMarkup);
			this._pswpEl = document.querySelector('.pswp');
		}

		if (this._galleries.length > 0 && this._pswpEl) {
			this._gallery = null;
			this.buildGalleries();
		}
	}

	buildGalleries() {
		this._galleries.forEach(gallery => {
			const images = Array.from(gallery.querySelectorAll('[data-nelement="gallery-img"]'));

			// Create a slide object from this gallery image element
			const slides = images.map(img => ({
				src: img.dataset.srcLrg,
				msrc: img.dataset.srcMed,
				w: img.dataset.size.split('x')[0],
				h: img.dataset.size.split('x')[1],
				title: img.dataset.caption,
			}));

			images.forEach(img => {
				// Add a click event listener to this gallery image element
				img.addEventListener('click', e => {
					e.preventDefault();

					const options = {
						bgOpacity: 0.9,
						showHideOpacity: true,
						index: images.indexOf(img),
						history: false,
					};

					this._gallery = new PhotoSwipe(this._pswpEl, PhotoSwipeUI_Default, slides, options);

					this._gallery.init();
				});
			});
		});

		// Register events on gallery open toggles
		this._galleryToggles.forEach(toggle => {
			toggle.addEventListener('click', e => {
				e.preventDefault();
				console.log(toggle.parentElement);
				toggle.parentElement.parentElement.querySelector('[data-nelement="gallery-img"]').click();
			});
		});
	}
}

export default new ImageGalleries();
