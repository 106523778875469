import 'picturefill';
import AOS from 'aos';
import Pjax from 'pjax';
import topbar from 'topbar';

import ImageGalleries from './modules/galleries/image-galleries';
import Sharer from './modules/share/sharer';
import SmoothScroll from './modules/smooth-scroll/smooth-scroll';
import AutoResizeVideos from './modules/videos/autoresize';
import Carousels from './modules/carousels/carousels';
import TestimonialsCarousel from './modules/carousels/testimonials';

// Picture element HTML5 shiv
document.createElement('picture');

AOS.init({
	duration: 750,
	once: true,
});

const pjax = new Pjax({
	elements: 'a[href], form[action]',
	selectors: ['head', '[data-ui-section="main"]', '[data-ui-section="nav"]'],
	switches: {
		head(oldEl, newEl, options) {
			const oldMetaEls = oldEl.querySelectorAll('meta, title');
			const newMetaEls = newEl.querySelectorAll('meta, title');
			oldMetaEls.forEach(el => oldEl.removeChild(el));
			newMetaEls.forEach(el => oldEl.appendChild(el));
			this.onSwitch();
		},
		'[data-ui-section="main"]': Pjax.switches.sideBySide,
	},
	switchesOptions: {
		'[data-ui-section="main"]': {
			classNames: {
				// class added to the old element being replaced, e.g. a fade out
				remove: 'pjaxAnimated pjaxAnimated--fadeOut',
				// class added to the new element that is replacing the old one, e.g. a fade in
				add: 'pjaxAnimated pjaxAnimated--fadeIn',
				// class added on the element when navigating back
				backward: '',
				// class added on the element when navigating forward (used for new page too)
				forward: '',
			},
			callbacks: {
				removeElement(el) {
					// set body height to keep sticky header working
					document.body.style.height = `${document.body.clientHeight}px`;

					el.addEventListener('animationend', () => {
						// scroll to top when remove aniumation ends to prevent jank
						window.scrollTo(0, 0);
						// set body height to keep sticky header working
						document.body.style.height = 'auto';
					});
				},
			},
		},
	},
});

topbar.config({
	autoRun: true,
	barThickness: 4,
	barColors: {
		'0': 'rgba(47, 120, 31, .5)',
		'1.0': 'rgba(47, 120, 31, .75)',
	},
	shadowBlur: 5,
	shadowColor: 'rgba(0, 0, 0, .4)',
});

function pjaxSend(e) {
	topbar.show();
	document.querySelector('#mobile-nav-toggle').checked = false;
}

function pjaxComplete(e) {
	// Jump to anchor on complete if the trigger el
	// specified a data attribute data-pjax-anchor.
	const triggerEl = e.triggerElement;

	if (triggerEl && triggerEl.dataset.pjaxAnchor != null) {
		const anchorEl = document.getElementById(triggerEl.dataset.pjaxAnchor);
		setTimeout(() => {
			window.scrollTo(0, anchorEl.getBoundingClientRect().top);
		}, 0);
	}

	topbar.hide();
	Sharer.init();
	AutoResizeVideos.init();
	SmoothScroll.init();
	Carousels.init();
	TestimonialsCarousel.init();
	ImageGalleries.init();
}

function pjaxError(event) {
	if (event.request.status === 404) {
		pjax.loadUrl('/404-page-not-found/');
	}
}

document.addEventListener('pjax:send', pjaxSend);
document.addEventListener('pjax:complete', pjaxComplete);
document.addEventListener('pjax:error', pjaxError);
